@import url(https://fonts.googleapis.com/css?family=Lato:100,100italic,300,300italic,regular,italic,700,700italic,900,900italic);
html,
body {
  font-family: "Lato", sans-serif;
  background-color: #f7f9f8;
}

.animate__animated {
  --animate-duration: 0.5s;
}

.svg {
  transform: rotate(180deg);
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 400;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 200, 400;
    stroke-dashoffset: -70;
  }
  100% {
    stroke-dasharray: 200, 400;
    stroke-dashoffset: -280;
  }
}
@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.page-loaded .page-preloader {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.4s ease-in-out 0.2s, visibility 0s ease-in-out 0.6s;
  transition: opacity 0.4s ease-in-out 0.2s, visibility 0s ease-in-out 0.6s;
}

.page-preloader {
  z-index: 9999;
  background: #fff;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.page-preloader .circular {
  animation: rotate 2s linear infinite;
  height: 100px;
  left: 50%;
  position: absolute;
  top: 50%;
  margin: -50px 0 0 -50px;
  transition: all 0.2s ease;
  transform: rotate(360deg);
  width: 100px;
  z-index: 4;
}
.page-preloader .circular .path {
  stroke-dasharray: 1, 1000;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite;
  stroke: #49191f;
}
.page-preloader .logo {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -50px 0 0 -50px;
  height: 100px;
  width: 100px;
  z-index: 2;
}
.page-preloader .logo .st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: none;
}
.page-preloader .logo .st1 {
  fill: none;
}
.page-preloader .logo .st2 {
  fill: #49191f;
}

.header {
  background: linear-gradient(180deg, #f7f9f9 0, rgba(247, 249, 249, 0.58) 71.35%, rgba(247, 249, 249, 0) 100%);
  position: absolute;
  z-index: 2;
  width: 100%;
  padding: 48px 40px 0;
}
.header-all {
  display: flex;
  flex-direction: column;
  height: auto;
}
.header-all__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}
.header-all__top .header-profile {
  display: flex;
  align-items: center;
}
.header-all__top .header-profile__phone a {
  display: flex;
  align-items: center;
}
.header-all__top .header-profile__phone a .phone-number {
  color: #454644;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.02em;
  margin-left: 24px;
  transition: 1s;
}
.header-all__top .header-profile__phone a .phone-number:hover {
  color: #707070;
  transform: scale(1.1);
}
.header-all__top .header-profile__account {
  margin-left: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3px 0 35px;
  width: 232px;
  height: 40px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 28px;
  position: relative;
  transition: 0.3s;
}
.header-all__top .header-profile__account:hover .dropdown-block {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease-in-out, visibility 0s ease-in-out 0s;
}
.header-all__top .header-profile__account .account-sign {
  display: flex;
  align-items: center;
}
.header-all__top .header-profile__account .account-sign__login {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #454644;
}
.header-all__top .header-profile__account .account-sign__reg {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #454644;
}
.header-all__top .header-profile__account .account-sign span {
  margin: 0 4px;
}
.header-all__top .header-profile__account .account-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background: #F7F9F9;
}
.header-all__top .header-profile__account .dropdown-block {
  position: absolute;
  top: 100%;
  background: #83B143;
  border-radius: 20px;
  width: 232px;
  left: 50%;
  margin: 7px 0 0 -116px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0s ease-in-out 0.3s;
}
.header-all__top .header-profile__account .dropdown-block a {
  display: block;
  padding: 17px 20px;
  color: #fff;
  font-size: 14px;
}
.header-all__top .header-profile__account .dropdown-block a:hover {
  color: #333333;
}
.header-all__top .header-profile__account .dropdown-block a:first-child {
  border-bottom: 1px solid #eae8e9;
}
.header-all__top .header-profile__account .dropdown-block:before {
  content: "";
  width: 0;
  border: solid transparent;
  height: 0;
  bottom: 100%;
  left: 35px;
  display: block;
  position: absolute;
  border-width: 0 5.5px 6px 5.5px;
  border-bottom-color: #83B143;
}
.header-all__nav {
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 1px 3px rgba(25, 30, 30, 0.08);
  border-radius: 28px;
}
.header-all__nav .nav-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-all__nav .nav-list .nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  padding: 0 25px;
  transition: 1s;
  cursor: pointer;
  flex: 1;
  width: 100%;
  border-radius: 16px 16px 0 0;
}
.header-all__nav .nav-list .nav-item a {
  color: #454644;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}
.header-all__nav .nav-list .nav-item:first-child {
  border-radius: 28px 28px 0 28px;
}
.header-all__nav .nav-list .nav-item:first-child:hover {
  border-radius: 28px 28px 0 28px;
}
.header-all__nav .nav-list .nav-item:last-child {
  cursor: auto;
  margin-right: 0;
  padding: 0 0 0 25px;
  justify-content: flex-end;
}
.header-all__nav .nav-list .nav-item:last-child:hover {
  background: transparent;
}
.header-all__nav .nav-list .nav-item form {
  display: flex;
  align-items: center;
  padding-right: 3px;
}
.header-all__nav .nav-list .nav-item form input {
  border: 0;
  outline: none;
  width: 220px;
  font-weight: 400;
  font-size: 14px;
  height: 36px;
  line-height: 17px;
  color: #919497;
  padding-bottom: 6px;
  border-bottom: 1px solid #D0D0D0;
}
.header-all__nav .nav-list .nav-item form .search {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 11px;
  line-height: normal;
  font-size: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #83B143;
}
.header-all__nav .nav-list .nav-item:hover {
  background: #83B143;
  border-radius: 16px 16px 0px 0px;
}
.header-all__nav .nav-list .nav-item:hover a {
  color: #fff;
}
.header-all__nav .nav-list .hr-wrapper .nav-hr {
  height: 32px;
  -moz-width: 2px;
  width: 1px;
  background: #E5E5E5;
  border-radius: 1px;
}
@media (min-width: 1920px) {
  .header {
    width: 100%;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .header .header-all {
    width: 1920px;
    margin: 0 auto;
  }
}
@media (max-width: 1320px) {
  .header-all__nav .nav-list .nav-item a {
    font-size: 13px;
  }
}
@media (max-width: 1280.98px) {
  .header-all__nav .nav-list {
    flex-wrap: nowrap;
  }
  .header-all__nav .nav-list .nav-item {
    width: fit-content;
    padding: 0 15px;
  }
  .header-all__nav .nav-list .nav-item a {
    font-size: 12px;
  }
  .header-all__nav .nav-list .nav-item:last-child {
    padding: 0 0 0 15px;
    width: auto;
  }
  .header-all__nav .nav-list .nav-item:last-child form input {
    width: auto;
  }
}
@media (max-width: 1024.98px) {
  .header {
    display: none;
  }
}

.headerAdaptive {
  display: none;
}
.headerAdaptive-all {
  display: flex;
  flex-direction: column;
}
.headerAdaptive-all__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 12px 16px;
}
.headerAdaptive-all__top .logo img {
  max-width: 187px;
}
.headerAdaptive-all__top .right {
  display: flex;
  align-items: center;
}
.headerAdaptive-all__top .right-phone, .headerAdaptive-all__top .right-acc {
  cursor: pointer;
  background: #F7F9F9;
  border-radius: 50%;
  border: 1px solid #FFFFFF;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 3px rgba(25, 30, 30, 0.08);
}
.headerAdaptive-all__top .right-acc {
  margin-left: 16px;
}
.headerAdaptive-all__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f7f9f9;
  padding: 15px 16px;
  height: 48px;
}
.headerAdaptive-all__bottom .menu-wrapper {
  display: block;
  width: 22px;
  height: 18px;
  cursor: pointer;
}
.headerAdaptive-all__bottom .menu-wrapper .hamburger-menu {
  position: relative;
  transform: translateY(8px);
  background: #454644;
  transition: all 0ms 300ms;
}
.headerAdaptive-all__bottom .menu-wrapper .hamburger-menu::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 8px;
  background: #454644;
  transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.headerAdaptive-all__bottom .menu-wrapper .hamburger-menu::after {
  content: "";
  position: absolute;
  left: 0;
  top: 8px;
  background: #454644;
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.headerAdaptive-all__bottom .menu-wrapper .hamburger-menu.animate {
  background: rgba(255, 255, 255, 0);
}
.headerAdaptive-all__bottom .menu-wrapper .hamburger-menu.animate::before {
  bottom: 0;
  transform: rotate(-45deg);
  transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.headerAdaptive-all__bottom .menu-wrapper .hamburger-menu.animate::after {
  top: 0;
  transform: rotate(45deg);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.headerAdaptive-all__bottom .menu-wrapper .hamburger-menu,
.headerAdaptive-all__bottom .menu-wrapper .hamburger-menu:after,
.headerAdaptive-all__bottom .menu-wrapper .hamburger-menu:before {
  width: 22px;
  height: 2px;
  border-radius: 2px;
}
.headerAdaptive-all__bottom .menu-two {
  background: #83B143;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  border-radius: 0px 8px 0px 0px;
  position: relative;
}
.headerAdaptive-all__bottom .menu-two .hamburger-menu.animate {
  position: absolute;
  top: 35%;
}
.headerAdaptive-all__bottom .menu-two .hamburger-menu.animate::after, .headerAdaptive-all__bottom .menu-two .hamburger-menu.animate::before {
  background-color: #fff;
}
.headerAdaptive-all__bottom .menu-form form {
  display: flex;
  align-items: center;
}
.headerAdaptive-all__bottom .menu-form form input {
  border: 0;
  outline: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #454644;
  text-align: right;
  width: 193px;
  background: transparent;
}
.headerAdaptive-all__bottom .menu-form form svg {
  width: 16px;
  height: 16px;
  margin-left: 16px;
  cursor: pointer;
}
.headerAdaptive-all__bottom .menu-form form svg path {
  fill: #C41D39;
}
.headerAdaptive-all .bottom-two {
  padding: 15px 16px 15px 0;
}
@media (max-width: 1024.98px) {
  .headerAdaptive {
    display: block;
  }
}

.adaptive {
  display: none;
  position: absolute;
  top: 112px;
  z-index: 2;
  width: 100%;
}
.adaptive-all {
  border-top: 2px solid #83B143;
}
.adaptive-all__nav .nav-list {
  display: flex;
  flex-direction: column;
}
.adaptive-all__nav .nav-list .nav-item {
  padding: 26px 32px;
  background: #FFFFFF;
  box-shadow: inset 0px -1px 0px #EAEDF3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.adaptive-all__nav .nav-list .nav-item a {
  font-weight: 600;
  font-size: 18px;
  line-height: 19px;
  color: #333333;
}
.adaptive-all__nav .nav-list .nav-item .overlay-arrow svg {
  transition: 0.5s;
  cursor: pointer;
}
.adaptive-all__nav .nav-list .open-active {
  background-color: #83B143;
}
.adaptive-all__nav .nav-list .open-active a {
  color: #fff;
}
.adaptive-all__nav .nav-list .open-active .overlay-arrow svg {
  transition: 0.5s;
}
.adaptive-all__nav .nav-list .open-active .overlay-arrow svg path {
  fill: #fff;
}

.overlay-two {
  display: none;
  padding: 0 40px;
  width: 100%;
  transition: opacity 0.3s ease-in-out, visibility 0s ease-in-out 0s;
}
.overlay-two-all {
  display: flex;
  width: 100%;
  border-top: 4px solid #83B143;
  transition: opacity 0.3s ease-in-out, visibility 0s ease-in-out 0s;
  background-color: #fff;
  border-radius: 0 0 28px 28px;
}
.overlay-two-all .overlay-item {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.overlay-two-all .overlay-item:first-child .nav-list {
  border-radius: 0 0 0 28px;
}
.overlay-two-all .overlay-item:last-child .nav-list {
  border-radius: 0 0 28px 0;
}
.overlay-two-all .overlay-item .nav-list {
  padding: 20px;
  box-shadow: 0px 1px 3px rgba(25, 30, 30, 0.08);
}
.overlay-two-all .overlay-item .nav-list:hover {
  background: linear-gradient(156.08deg, #ECF2FF 0%, rgba(197, 234, 255, 0.5) 82.16%), #FFFFFF;
}
.overlay-two-all .overlay-item .nav-list a {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #454644;
  margin-bottom: 12px;
  display: block;
}
.overlay-two-all .overlay-item .nav-list a:hover {
  text-decoration: underline;
}
.overlay-two-all .overlay-item .nav-list a:last-child {
  margin-bottom: 0;
}
@media (min-width: 1920px) {
  .overlay-two {
    width: 1920px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

.overlay {
  display: none;
  padding: 0 40px;
  width: 100%;
  transition: opacity 0.3s ease-in-out, visibility 0s ease-in-out 0s;
}
.overlay-all {
  display: flex;
  width: 100%;
  border-top: 4px solid #83B143;
  transition: opacity 0.3s ease-in-out, visibility 0s ease-in-out 0s;
}
.overlay-all .overlay-item {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.overlay-all .overlay-item:first-child {
  max-width: 736px;
}
.overlay-all .overlay-item__title {
  padding: 25px 10px 20px 40px;
  background: #F5F9FF;
  box-shadow: inset 0px -1px 0px #EAEDF3, inset -1px 0px 0px #EAEDF3;
}
.overlay-all .overlay-item .nav-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 736px;
}
.overlay-all .overlay-item .nav-wrapper .overlay-item__nav {
  display: flex;
  flex-direction: column;
  padding: 16px 16px 16px 40px;
  background: #FFFFFF;
  width: 368px;
  box-shadow: inset -1px 0px 0px #EAEDF3, inset 0px -1px 0px #EAEDF3;
}
.overlay-all .overlay-item .nav-wrapper .overlay-item__nav:hover {
  background: linear-gradient(156.08deg, #ECF2FF 0%, rgba(197, 234, 255, 0.5) 82.16%), #FFFFFF;
}
.overlay-all .overlay-item .nav-wrapper .overlay-item__nav:hover .nav-title {
  color: #333333;
}
.overlay-all .overlay-item .nav-wrapper .overlay-item__nav:nth-child(1) {
  order: 1;
  height: 184px;
}
.overlay-all .overlay-item .nav-wrapper .overlay-item__nav:nth-child(2) {
  order: 3;
  height: 184px;
}
.overlay-all .overlay-item .nav-wrapper .overlay-item__nav:nth-child(3) {
  order: 5;
  height: 64px;
  border-radius: 0 0 0 28px;
}
.overlay-all .overlay-item .nav-wrapper .overlay-item__nav:nth-child(4) {
  order: 2;
  height: 144px;
}
.overlay-all .overlay-item .nav-wrapper .overlay-item__nav:nth-child(5) {
  order: 4;
  height: 184px;
  margin-top: -40px;
}
.overlay-all .overlay-item .nav-wrapper .overlay-item__nav:nth-child(6) {
  order: 6;
  height: 104px;
  margin-top: -40px;
}
.overlay-all .overlay-item .nav-wrapper .overlay-item__nav .nav-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #83B143;
  margin-bottom: 16px;
  transition: 0.5s;
}
.overlay-all .overlay-item .nav-wrapper .overlay-item__nav .nav-list {
  padding-left: 16px;
}
.overlay-all .overlay-item .nav-wrapper .overlay-item__nav .nav-list a {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #454644;
  margin-bottom: 12px;
  display: block;
}
.overlay-all .overlay-item .nav-wrapper .overlay-item__nav .nav-list a:hover {
  text-decoration: underline;
}
.overlay-all .overlay-item .nav-wrapper .overlay-item__nav .nav-list a:last-child {
  margin-bottom: 0;
}
.overlay-all__two:last-child .overlay-item__nav {
  border-radius: 0 0 28px 0;
}
.overlay-all__two .overlay-item__title {
  padding: 25px 10px 20px 25px;
}
.overlay-all__two .overlay-item__nav {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding: 16px 16px 16px 16px;
  background: #FFFFFF;
  box-shadow: inset -1px 0px 0px #EAEDF3, inset 0px -1px 0px #EAEDF3;
}
.overlay-all__two .overlay-item__nav:hover {
  background: linear-gradient(156.08deg, #ECF2FF 0%, rgba(197, 234, 255, 0.5) 82.16%), #FFFFFF;
}
.overlay-all__two .overlay-item__nav .nav-list a {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #454644;
  margin-bottom: 12px;
  display: block;
}
.overlay-all__two .overlay-item__nav .nav-list a:hover {
  text-decoration: underline;
}
.overlay-all__two .overlay-item__nav .nav-list a:last-child {
  margin-bottom: 0;
}
@media (min-width: 1920px) {
  .overlay {
    width: 1920px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
@media (min-width: 1025px) and (max-width: 1300px) {
  .overlay-all .overlay-item .nav-wrapper {
    width: 450px;
  }
  .overlay-all .overlay-item .nav-wrapper .overlay-item__nav {
    padding: 16px 16px 16px 25px;
    width: 50%;
    height: auto !important;
    margin-top: 0 !important;
  }
  .overlay-all .overlay-item .nav-wrapper .overlay-item__nav .nav-list {
    padding-left: 10px;
  }
}

.overlay-adaptive-one {
  display: none;
}
.overlay-adaptive-one .nav-list {
  display: flex;
  flex-direction: column;
}
.overlay-adaptive-one .nav-list .nav-item {
  padding: 13px 32px;
  background: #FFFFFF;
  box-shadow: inset 0px -1px 0px #EAEDF3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.overlay-adaptive-one .nav-list .nav-item a {
  font-weight: 400;
  font-size: 18px;
  line-height: 19px;
  color: #333333;
}
.overlay-adaptive-one .nav-list .nav-item .overlay-arrow svg {
  transition: 0.5s;
  cursor: pointer;
}
.overlay-adaptive-one .nav-list .open-active-two {
  background-color: #f5f9ff;
}
.overlay-adaptive-one .nav-list .open-active-two a {
  color: #333333;
}
.overlay-adaptive-one .nav-list .open-active-two .overlay-arrow svg {
  transition: 0.5s;
}
.overlay-adaptive-one .nav-list .open-active-two .overlay-arrow svg path {
  fill: #919497;
}
.overlay-adaptive-one .nav-list .drop-two {
  display: none;
}
.overlay-adaptive-one .nav-list .drop-two .nav-wrapper .overlay-item__nav {
  display: flex;
  flex-direction: column;
  padding: 16px 16px 16px 32px;
  background: #FFFFFF;
  width: 100%;
  box-shadow: inset -1px 0px 0px #EAEDF3, inset 0px -1px 0px #EAEDF3;
  height: auto;
}
.overlay-adaptive-one .nav-list .drop-two .nav-wrapper .overlay-item__nav:hover {
  background: linear-gradient(156.08deg, #ECF2FF 0%, rgba(197, 234, 255, 0.5) 82.16%), #FFFFFF;
}
.overlay-adaptive-one .nav-list .drop-two .nav-wrapper .overlay-item__nav:hover .nav-title {
  color: #333333;
}
.overlay-adaptive-one .nav-list .drop-two .nav-wrapper .overlay-item__nav .nav-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #83B143;
  margin-bottom: 16px;
  transition: 0.5s;
}
.overlay-adaptive-one .nav-list .drop-two .nav-wrapper .overlay-item__nav .nav-list {
  padding-left: 16px;
}
.overlay-adaptive-one .nav-list .drop-two .nav-wrapper .overlay-item__nav .nav-list a {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #454644;
  margin-bottom: 12px;
  display: block;
}
.overlay-adaptive-one .nav-list .drop-two .nav-wrapper .overlay-item__nav .nav-list a:hover {
  text-decoration: underline;
}
.overlay-adaptive-one .nav-list .drop-two .nav-wrapper .overlay-item__nav .nav-list a:last-child {
  margin-bottom: 0;
}
.overlay-adaptive-one .nav-list .drop-three {
  display: none;
}
.overlay-adaptive-one .nav-list .drop-three .overlay-item__nav {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding: 16px 16px 16px 32px;
  background: #FFFFFF;
  box-shadow: inset -1px 0px 0px #EAEDF3, inset 0px -1px 0px #EAEDF3;
}
.overlay-adaptive-one .nav-list .drop-three .overlay-item__nav:hover {
  background: linear-gradient(156.08deg, #ECF2FF 0%, rgba(197, 234, 255, 0.5) 82.16%), #FFFFFF;
}
.overlay-adaptive-one .nav-list .drop-three .overlay-item__nav .nav-list a {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #454644;
  margin-bottom: 12px;
  display: block;
}
.overlay-adaptive-one .nav-list .drop-three .overlay-item__nav .nav-list a:hover {
  text-decoration: underline;
}
.overlay-adaptive-one .nav-list .drop-three .overlay-item__nav .nav-list a:last-child {
  margin-bottom: 0;
}
.overlay-adaptive-one .nav-list .drop-four {
  display: none;
}
.overlay-adaptive-one .nav-list .drop-four .overlay-item__nav {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding: 16px 16px 16px 32px;
  background: #FFFFFF;
  box-shadow: inset -1px 0px 0px #EAEDF3, inset 0px -1px 0px #EAEDF3;
}
.overlay-adaptive-one .nav-list .drop-four .overlay-item__nav:hover {
  background: linear-gradient(156.08deg, #ECF2FF 0%, rgba(197, 234, 255, 0.5) 82.16%), #FFFFFF;
}
.overlay-adaptive-one .nav-list .drop-four .overlay-item__nav .nav-list a {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #454644;
  margin-bottom: 12px;
  display: block;
}
.overlay-adaptive-one .nav-list .drop-four .overlay-item__nav .nav-list a:hover {
  text-decoration: underline;
}
.overlay-adaptive-one .nav-list .drop-four .overlay-item__nav .nav-list a:last-child {
  margin-bottom: 0;
}
.overlay-adaptive-one .nav-list .drop-five {
  display: none;
}
.overlay-adaptive-one .nav-list .drop-five .overlay-item__nav {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding: 16px 16px 16px 32px;
  background: #FFFFFF;
  box-shadow: inset -1px 0px 0px #EAEDF3, inset 0px -1px 0px #EAEDF3;
}
.overlay-adaptive-one .nav-list .drop-five .overlay-item__nav:hover {
  background: linear-gradient(156.08deg, #ECF2FF 0%, rgba(197, 234, 255, 0.5) 82.16%), #FFFFFF;
}
.overlay-adaptive-one .nav-list .drop-five .overlay-item__nav .nav-list a {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #454644;
  margin-bottom: 12px;
  display: block;
}
.overlay-adaptive-one .nav-list .drop-five .overlay-item__nav .nav-list a:hover {
  text-decoration: underline;
}
.overlay-adaptive-one .nav-list .drop-five .overlay-item__nav .nav-list a:last-child {
  margin-bottom: 0;
}

.overlay-adaptive-two {
  display: none;
}
.overlay-adaptive-two-all {
  display: flex;
  width: 100%;
  transition: opacity 0.3s ease-in-out, visibility 0s ease-in-out 0s;
  background-color: #fff;
  flex-direction: column;
}
.overlay-adaptive-two-all .overlay-item {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.overlay-adaptive-two-all .overlay-item .nav-list {
  padding: 20px;
  border-bottom: 1px solid rgba(25, 30, 30, 0.08);
}
.overlay-adaptive-two-all .overlay-item .nav-list:hover {
  background: linear-gradient(156.08deg, #ECF2FF 0%, rgba(197, 234, 255, 0.5) 82.16%), #FFFFFF;
}
.overlay-adaptive-two-all .overlay-item .nav-list a {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #454644;
  margin-bottom: 12px;
  display: block;
}
.overlay-adaptive-two-all .overlay-item .nav-list a:hover {
  text-decoration: underline;
}
.overlay-adaptive-two-all .overlay-item .nav-list a:last-child {
  margin-bottom: 0;
}

.promo {
  margin-bottom: 30px;
}
.promo-all .swiper {
  width: 100%;
  height: 752px;
}
.promo-all .swiper-wrapper .swiper-slide {
  background-image: url(../img/back-one.png);
  background: linear-gradient(180deg, #F7F9F9 0%, rgba(247, 249, 249, 0.58) 71.35%, rgba(247, 249, 249, 0) 100%);
  background-position: center;
  background-size: cover;
}
.promo-all .swiper-wrapper .swiper-slide .container {
  height: 100%;
}
.promo-all .swiper-wrapper .swiper-slide .container .swiper-slide-desc {
  display: flex;
  flex-direction: column;
  margin-top: 10%;
  height: 100%;
  justify-content: center;
  margin-bottom: 50px;
}
.promo-all .swiper-wrapper .swiper-slide .container .swiper-slide-desc .title {
  margin-bottom: 42px;
  color: #454644;
  font-weight: 500;
  font-size: 66px;
  line-height: 79px;
}
.promo-all .swiper-wrapper .swiper-slide .container .swiper-slide-desc .subtitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 34px;
  color: #333333;
  height: 72px;
}
.promo-all .swiper-wrapper .swiper-slide .container .swiper-slide-desc .buttons {
  display: flex;
  align-items: center;
}
.promo-all .swiper-wrapper .swiper-slide .container .swiper-slide-desc .buttons a {
  display: block;
  padding: 10px 29px 13px 29px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #454644;
  border: 2px solid #C41D39;
  border-radius: 21px;
  margin-right: 30px;
}
.promo-all .swiper-wrapper .swiper-slide .container .swiper-slide-desc .buttons a:hover {
  color: #fff;
  background: #C41D39;
}
.promo-all .swiper-wrapper .swiper-slide .container .swiper-slide-desc .buttons a:last-child {
  margin-right: 0;
}
.promo-all .swiper .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.promo-all .swiper .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 14px 0 0;
  width: 13px;
  height: 13px;
  background: #fff;
  opacity: 1;
  cursor: pointer;
}
.promo-all .swiper .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet:last-child,
.promo-all .swiper .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet:last-child {
  margin: 0;
}
.promo-all .swiper .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet-active,
.promo-all .swiper .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet-active {
  background: #83B143;
}
@media (max-width: 1280.98px) {
  .promo-all .swiper-wrapper .swiper-slide {
    background-image: url(../img/back-one.png);
    background-size: cover;
    background-position: center !important;
  }
  .promo-all .swiper-wrapper .swiper-slide .container .swiper-slide-desc {
    padding: 0 50px;
  }
}
@media (max-width: 1024.98px) {
  .promo-all .swiper-wrapper .swiper-slide {
    background-image: url(../img/back-one.png);
    background-size: cover;
    background-position: center !important;
  }
  .promo-all .swiper-wrapper .swiper-slide .container {
    background: linear-gradient(180deg, rgba(247, 249, 249, 0.7) 0, rgba(247, 249, 249, 0.58) 71.35%, rgba(247, 249, 249, 0) 100%);
  }
  .promo-all .swiper-wrapper .swiper-slide .container .swiper-slide-desc {
    margin-left: 0;
    padding-left: 15%;
    margin-top: 0;
    height: 438px;
    justify-content: flex-end;
  }
}
@media (max-width: 767.98px) {
  .promo-all .swiper {
    height: 456px;
  }
  .promo-all .swiper-wrapper .swiper-slide {
    background-image: url(../img/back-one.png);
    background-size: cover;
    background-position: center !important;
  }
  .promo-all .swiper-wrapper .swiper-slide .container {
    background: linear-gradient(180deg, rgba(247, 249, 249, 0.7) 0, rgba(247, 249, 249, 0.58) 71.35%, rgba(247, 249, 249, 0) 100%);
  }
  .promo-all .swiper-wrapper .swiper-slide .container .swiper-slide-desc {
    margin-top: 0;
    padding: 0 20px;
    margin-left: 0;
    height: 287px;
    justify-content: flex-end;
  }
  .promo-all .swiper-wrapper .swiper-slide .container .swiper-slide-desc .title {
    font-size: 45px;
    line-height: 57px;
    margin-bottom: 20px;
  }
  .promo-all .swiper-wrapper .swiper-slide .container .swiper-slide-desc .subtitle {
    height: auto;
    margin-bottom: 20px;
  }
  .promo-all .swiper .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.promo-all .swiper .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 14px 0 0;
    width: 13px;
    height: 13px;
    background: #fff;
    opacity: 1;
    cursor: pointer;
  }
  .promo-all .swiper .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet:last-child,
.promo-all .swiper .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet:last-child {
    margin: 0;
  }
  .promo-all .swiper .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet-active,
.promo-all .swiper .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet-active {
    background: #83B143;
  }
}
@media (max-width: 660px) {
  .promo-all .swiper {
    height: 456px;
  }
  .promo-all .swiper-wrapper .swiper-slide {
    background-image: url(../img/back-one.png);
    background-size: cover;
    background-position: -500px !important;
  }
  .promo-all .swiper-wrapper .swiper-slide .container {
    background: linear-gradient(180deg, rgba(247, 249, 249, 0.7) 0, rgba(247, 249, 249, 0.58) 71.35%, rgba(247, 249, 249, 0) 100%);
  }
  .promo-all .swiper-wrapper .swiper-slide .container .swiper-slide-desc {
    margin-top: 0;
    padding: 0 20px;
    margin-left: 0;
    height: 287px;
    justify-content: flex-end;
  }
  .promo-all .swiper-wrapper .swiper-slide .container .swiper-slide-desc .title {
    font-size: 45px;
    line-height: 57px;
    margin-bottom: 20px;
  }
  .promo-all .swiper-wrapper .swiper-slide .container .swiper-slide-desc .subtitle {
    height: auto;
    margin-bottom: 20px;
  }
  .promo-all .swiper .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.promo-all .swiper .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 14px 0 0;
    width: 13px;
    height: 13px;
    background: #fff;
    opacity: 1;
    cursor: pointer;
  }
  .promo-all .swiper .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet:last-child,
.promo-all .swiper .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet:last-child {
    margin: 0;
  }
  .promo-all .swiper .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet-active,
.promo-all .swiper .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet-active {
    background: #83B143;
  }
}
@media (max-width: 576.98px) {
  .promo-all .swiper-wrapper .swiper-slide {
    background-size: cover;
    background-position: center;
  }
  .promo-all .swiper-wrapper .swiper-slide .container .swiper-slide-desc {
    margin-top: 0px;
    padding: 0;
    margin-left: 0;
    height: 100%;
    justify-content: center;
  }
  .promo-all .swiper-wrapper .swiper-slide .container .swiper-slide-desc .title {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 16px;
  }
  .promo-all .swiper-wrapper .swiper-slide .container .swiper-slide-desc .subtitle {
    height: auto;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 40px;
  }
  .promo-all .swiper-wrapper .swiper-slide .container .swiper-slide-desc .buttons {
    flex-direction: column;
  }
  .promo-all .swiper-wrapper .swiper-slide .container .swiper-slide-desc .buttons a {
    margin-right: 0px;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
  }
  .promo-all .swiper-wrapper .swiper-slide .container .swiper-slide-desc .buttons a:last-child {
    margin-bottom: 0;
  }
  .promo-all .swiper .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.promo-all .swiper .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 14px 0 0;
    width: 13px;
    height: 13px;
    background: #fff;
    opacity: 1;
    cursor: pointer;
  }
  .promo-all .swiper .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet:last-child,
.promo-all .swiper .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet:last-child {
    margin: 0;
  }
  .promo-all .swiper .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet-active,
.promo-all .swiper .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet-active {
    background: #83B143;
  }
}

.services {
  margin-bottom: 60px;
}
.services .container .services-all {
  display: flex;
  flex-direction: column;
}
.services .container .services-all__title {
  font-weight: 500;
  font-size: 38px;
  line-height: 46px;
  color: #000;
  margin-bottom: 42px;
}
.services .container .services-all__main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.services .container .services-all__main .main-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 38px 40px 30px 40px;
  height: 362px;
  background-size: cover;
}
.services .container .services-all__main .main-item__top {
  display: flex;
  flex-direction: column;
  max-width: 290px;
}
.services .container .services-all__main .main-item__top .title {
  font-weight: 400;
  font-size: 24px;
  line-height: 27px;
  color: #333333;
  margin-bottom: 17px;
}
.services .container .services-all__main .main-item__top .subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #454644;
}
.services .container .services-all__main .main-item__bottom .button {
  transition: 1s;
}
.services .container .services-all__main .main-item__bottom .button:hover {
  transform: scale(1.1);
}
.services .container .services-all__main .main-item__bottom .button a {
  padding: 10px 29px 13px 29px;
  background-color: #83B143;
  border-radius: 21px;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: block;
  width: fit-content;
}
.services .container .services-all__main .main-item:nth-child(1) {
  background-image: url(../img/card1.png);
}
.services .container .services-all__main .main-item:nth-child(2) {
  background-image: url(../img/card2.png);
}
.services .container .services-all__main .main-item:nth-child(3) {
  background-image: url(../img/card3.png);
}
.services .container .services-all__third {
  background-image: url(../img/back-two.png);
  display: flex;
  flex-direction: column;
  height: 368px;
  align-items: end;
  justify-content: center;
}
.services .container .services-all__third .third-wrapper {
  max-width: 570px;
}
.services .container .services-all__third .third-wrapper .title {
  margin-bottom: 40px;
  font-weight: 400;
  font-size: 38px;
  line-height: 44px;
  color: #454644;
}
.services .container .services-all__third .third-wrapper .text {
  display: flex;
  align-items: center;
  margin-bottom: 48px;
}
.services .container .services-all__third .third-wrapper .text span {
  font-weight: 400;
  font-size: 16px;
  line-height: 44px;
  color: #454644;
}
.services .container .services-all__third .third-wrapper .text img {
  margin-left: 16px;
}
.services .container .services-all__third .third-wrapper .buttons {
  display: flex;
  align-items: center;
}
.services .container .services-all__third .third-wrapper .buttons a {
  padding: 10px 29px 13px 29px;
  background-color: transparent;
  border: 2px solid #0066B3;
  border-radius: 21px;
  color: #0066B3;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: block;
  width: fit-content;
  transition: 1s;
}
.services .container .services-all__third .third-wrapper .buttons a:hover {
  transform: scale(1.1);
}
.services .container .services-all__third .third-wrapper .buttons a:last-child {
  color: #fff;
  background-color: #0066B3;
  margin-left: 16px;
}
@media (max-width: 1280.98px) {
  .services .container {
    padding: 0 15px;
  }
  .services .container .services-all {
    max-width: 100%;
    align-items: center;
  }
  .services .container .services-all__title {
    width: 100%;
  }
  .services .container .services-all__main {
    flex-wrap: nowrap;
    justify-content: center;
  }
  .services .container .services-all__main .main-item {
    flex-direction: column;
    margin-bottom: 0px;
    width: 100%;
    max-width: 370px;
    margin-right: 10px;
    padding: 30px;
  }
  .services .container .services-all__main .main-item:nth-child(1) {
    background-position: center;
  }
  .services .container .services-all__main .main-item:nth-child(2) {
    background-position: center;
  }
  .services .container .services-all__main .main-item:nth-child(3) {
    background-position: center;
  }
  .services .container .services-all__main .main-item:last-child {
    margin-right: 0;
  }
  .services .container .services-all__third {
    width: 100%;
    max-width: 770px;
    padding: 0 20px;
  }
  .services .container .services-all__third .third-wrapper {
    max-width: 400px;
  }
}
@media (max-width: 767.98px) {
  .services .container {
    padding: 0 15px;
  }
  .services .container .services-all__title {
    font-size: 24px;
    line-height: 29px;
  }
  .services .container .services-all__main {
    flex-direction: column;
  }
  .services .container .services-all__main .main-item {
    flex-direction: column;
    margin-bottom: 20px;
    width: 100% !important;
  }
  .services .container .services-all__main .main-item:last-child {
    margin-bottom: 0;
  }
  .services .container .services-all__main .main-item:nth-child(1) {
    margin-right: 0px;
  }
  .services .container .services-all__main .main-item:nth-child(2) {
    margin-left: 0px;
  }
  .services .container .services-all__main .main-item:last-child {
    margin-bottom: 0;
  }
  .services .container .services-all__third {
    background-image: url(../img/back-two-adaptive.png);
    height: 368px;
    max-width: 370px;
    background-size: cover;
    background-position: top;
    padding: 0 20px;
  }
  .services .container .services-all__third .third-wrapper {
    width: 100%;
    max-width: 570px;
    text-align: center;
  }
  .services .container .services-all__third .third-wrapper .title {
    font-size: 25px;
    line-height: 37px;
    margin-bottom: 20px;
  }
  .services .container .services-all__third .third-wrapper .text {
    flex-direction: column;
    text-align: center;
    margin-bottom: 25px;
  }
  .services .container .services-all__third .third-wrapper .text span {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 10px;
  }
  .services .container .services-all__third .third-wrapper .text img {
    margin-left: 0;
  }
  .services .container .services-all__third .third-wrapper .buttons {
    flex-direction: column;
  }
  .services .container .services-all__third .third-wrapper .buttons a:first-child {
    margin-bottom: 10px;
  }
  .services .container .services-all__third .third-wrapper .buttons a:last-child {
    margin-left: 0;
  }
}
@media (max-width: 576.98px) {
  .services .container {
    padding: 0 15px;
  }
}

.info {
  margin-bottom: 60px;
}
.info .container .info-all {
  display: flex;
  flex-direction: column;
}
.info .container .info-all__title {
  font-weight: 500;
  font-size: 38px;
  line-height: 46px;
  color: #000;
  margin-bottom: 42px;
}
.info .container .info-all__main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}
.info .container .info-all__main a .main-item {
  padding: 40px 40px 29px 40px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(25, 30, 30, 0.08);
  height: 288px;
  border: 1px solid #fff;
  transition: 0.5s;
}
.info .container .info-all__main a .main-item:hover {
  box-shadow: 0px 1px 10px rgba(25, 30, 30, 0.1);
  border: 1px solid #83B143;
}
.info .container .info-all__main a .main-item__img {
  margin-bottom: 24px;
}
.info .container .info-all__main a .main-item__title {
  margin-bottom: 16px;
  color: #333333;
  font-weight: 500;
  font-size: 24px;
  line-height: 27px;
}
.info .container .info-all__main a .main-item__subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #454644;
}
.info .container .info-all__main a .main-item__subtitle:hover {
  text-decoration: underline;
}
@media (max-width: 1280.98px) {
  .info .container {
    padding: 0 40px;
  }
  .info .container .info-all__main {
    grid-template-columns: 1fr 1fr;
  }
  .info .container .info-all__main a .main-item {
    height: 330px;
  }
}
@media (max-width: 767.98px) {
  .info .container {
    padding: 0 40px;
  }
  .info .container .info-all__title {
    font-size: 24px;
    line-height: 29px;
  }
  .info .container .info-all__main {
    grid-template-columns: 1fr;
  }
  .info .container .info-all__main a .main-item {
    height: auto;
  }
}
@media (max-width: 576.98px) {
  .info .container {
    padding: 0 15px;
  }
}

.desc {
  background-image: url(../img/back-three.png);
  background-position: center;
  background-size: cover;
  margin-bottom: 60px;
  height: 598px;
  display: flex;
  align-items: center;
}
.desc .container .desc-all {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.desc .container .desc-all__text {
  display: flex;
  flex-direction: column;
  max-width: 770px;
}
.desc .container .desc-all__text .title {
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  color: #333333;
  margin-bottom: 42px;
}
.desc .container .desc-all__text .title span {
  color: #83B143;
}
.desc .container .desc-all__text .subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin-bottom: 56px;
}
.desc .container .desc-all__text .button {
  transition: 1s;
  width: fit-content;
}
.desc .container .desc-all__text .button:hover {
  transform: scale(1.1);
}
.desc .container .desc-all__text .button a {
  padding: 13px 29px 17px 29px;
  background-color: #83B143;
  border-radius: 26px;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: block;
  width: fit-content;
}
@media (max-width: 1280.98px) {
  .desc .container {
    padding: 0 40px;
  }
  .desc .container .desc-all__logo {
    margin-left: 25px;
  }
}
@media (max-width: 767.98px) {
  .desc {
    height: auto;
    padding: 20px 0;
  }
  .desc .container {
    padding: 0 40px;
  }
  .desc .container .desc-all {
    flex-direction: column;
  }
  .desc .container .desc-all__text {
    order: 2;
  }
  .desc .container .desc-all__logo {
    order: 1;
    margin-bottom: 25px;
    margin-left: 0;
  }
  .desc .container .desc-all__logo img {
    max-width: 232px;
  }
}
@media (max-width: 576.98px) {
  .desc .container {
    padding: 0 15px;
  }
}

.news {
  margin-bottom: 152px;
}
.news .container .news-all {
  display: flex;
  flex-direction: column;
}
.news .container .news-all__title {
  font-weight: 500;
  font-size: 38px;
  line-height: 46px;
  color: #000;
  margin-bottom: 42px;
}
.news .container .news-all__main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.news .container .news-all__main .main-important {
  background: linear-gradient(133.59deg, #FFF3E1 0%, #F9FBFF 100%);
  filter: drop-shadow(0px 1px 3px rgba(25, 30, 30, 0.08));
  width: 370px;
  height: 362px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 40px 30px 40px;
}
.news .container .news-all__main .main-important__top {
  display: flex;
  flex-direction: column;
  max-width: 290px;
}
.news .container .news-all__main .main-important__top .title {
  font-weight: 400;
  font-size: 24px;
  line-height: 27px;
  color: #333333;
  margin-bottom: 17px;
}
.news .container .news-all__main .main-important__top .subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
}
.news .container .news-all__main .main-important__bottom .button {
  transition: 1s;
  width: fit-content;
}
.news .container .news-all__main .main-important__bottom .button:hover {
  transform: scale(1.1);
}
.news .container .news-all__main .main-important__bottom .button a {
  padding: 10px 29px 13px 29px;
  background-color: #83B143;
  border-radius: 26px;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: block;
  width: fit-content;
}
.news .container .news-all__main .main-last {
  display: flex;
  min-width: 0;
  max-width: 770px;
  overflow: hidden;
  box-shadow: 0px 1px 3px rgba(25, 30, 30, 0.08);
}
.news .container .news-all__main .main-last .swiper-two {
  width: 100%;
}
.news .container .news-all__main .main-last .swiper-two .swiper-slide {
  max-width: 770px;
  background-color: #fff;
  height: 362px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 40px 40px 30px 40px;
}
.news .container .news-all__main .main-last .swiper-two .swiper-slide .title {
  font-weight: 400;
  font-size: 24px;
  line-height: 27px;
  margin-bottom: 32px;
  color: #333333;
}
.news .container .news-all__main .main-last .swiper-two .swiper-slide .main {
  display: flex;
  justify-content: space-between;
}
.news .container .news-all__main .main-last .swiper-two .swiper-slide .main .main-item {
  max-width: 204px;
  display: flex;
  flex-direction: column;
}
.news .container .news-all__main .main-last .swiper-two .swiper-slide .main .main-item__date {
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #919497;
}
.news .container .news-all__main .main-last .swiper-two .swiper-slide .main .main-item__text a {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #333333;
  margin-bottom: 45px;
  display: block;
  transition: 0s;
}
.news .container .news-all__main .main-last .swiper-two .swiper-slide .main .main-item__text a:hover {
  color: #83B143;
}
.news .container .news-all__main .main-last .swiper-two .swiper-slide .buttons {
  display: flex;
  justify-content: space-between;
}
.news .container .news-all__main .main-last .swiper-two .swiper-slide .buttons .button {
  transition: 1s;
  width: fit-content;
}
.news .container .news-all__main .main-last .swiper-two .swiper-slide .buttons .button:hover {
  transform: scale(1.1);
}
.news .container .news-all__main .main-last .swiper-two .swiper-slide .buttons .button a {
  padding: 10px 29px 13px 29px;
  background-color: #83B143;
  border-radius: 26px;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: block;
  width: fit-content;
}
.news .container .news-all__main .main-last .swiper-two .swiper-slide .buttons .button-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 89px;
}
@media (max-width: 1280.98px) {
  .news .container {
    padding: 0 40px;
  }
  .news .container .news-all__main {
    flex-direction: row;
    align-items: stretch;
  }
  .news .container .news-all__main .main-important {
    width: 100%;
    max-width: 700px;
    margin-bottom: 0px;
    padding: 30px;
    margin-right: 10px;
  }
  .news .container .news-all__main .main-last {
    max-width: 700px;
    margin-left: 0;
  }
  .news .container .news-all__main .main-last .swiper-two {
    width: 100%;
  }
  .news .container .news-all__main .main-last .swiper-two .swiper-slide {
    padding: 30px;
    height: auto;
  }
  .news .container .news-all__main .main-last .swiper-two .swiper-slide .main {
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
    flex: 1;
  }
  .news .container .news-all__main .main-last .swiper-two .swiper-slide .main .main-item {
    margin-right: 15px;
  }
  .news .container .news-all__main .main-last .swiper-two .swiper-slide .main .main-item:last-child {
    margin-right: 0;
  }
  .news .container .news-all__main .main-last .swiper-two .swiper-slide .buttons .button {
    text-align: center;
    margin-right: 10px;
  }
  .news .container .news-all__main .main-last .swiper-two .swiper-slide .buttons .button-nav .swiper-next {
    margin-left: 5px;
  }
}
@media (max-width: 767.98px) {
  .news {
    margin-bottom: 46px;
  }
  .news .container {
    padding: 0 40px;
  }
  .news .container .news-all__title {
    font-size: 24px;
    line-height: 29px;
  }
  .news .container .news-all__main {
    flex-direction: column;
  }
  .news .container .news-all__main .main-important {
    width: 100%;
    max-width: 770px;
    margin-bottom: 20px;
  }
  .news .container .news-all__main .main-last {
    max-width: 100%;
  }
  .news .container .news-all__main .main-last .swiper-two {
    width: 100%;
  }
  .news .container .news-all__main .main-last .swiper-two .slide-one .main .main-item:nth-child(2), .news .container .news-all__main .main-last .swiper-two .slide-one .main .main-item:nth-child(3) {
    display: none;
  }
  .news .container .news-all__main .main-last .swiper-two .slide-two .main .main-item:nth-child(1), .news .container .news-all__main .main-last .swiper-two .slide-two .main .main-item:nth-child(3) {
    display: none;
  }
  .news .container .news-all__main .main-last .swiper-two .slide-three .main .main-item:nth-child(2), .news .container .news-all__main .main-last .swiper-two .slide-three .main .main-item:nth-child(1) {
    display: none;
  }
  .news .container .news-all__main .main-last .swiper-two .swiper-slide {
    padding: 30px;
    height: auto;
    justify-content: space-between;
  }
  .news .container .news-all__main .main-last .swiper-two .swiper-slide .main {
    flex-direction: column;
    align-items: start;
  }
  .news .container .news-all__main .main-last .swiper-two .swiper-slide .main .main-item {
    max-width: 100%;
  }
  .news .container .news-all__main .main-last .swiper-two .swiper-slide .buttons .button {
    text-align: center;
    margin-right: 10px;
  }
  .news .container .news-all__main .main-last .swiper-two .swiper-slide .buttons .button-nav .swiper-next {
    margin-left: 5px;
  }
}
@media (max-width: 576.98px) {
  .news .container {
    padding: 0 15px;
  }
}

.footer {
  background-color: #333333;
  padding-top: 20px;
  padding-bottom: 40px;
}
.footer-all {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer-all__nav {
  margin-bottom: 31px;
}
.footer-all__nav ul {
  display: flex;
  align-items: center;
}
.footer-all__nav ul li {
  margin-right: 30px;
}
.footer-all__nav ul li:last-child {
  margin-right: 0;
}
.footer-all__nav ul li a {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #7D7D7D;
}
.footer-all__nav ul li a:hover {
  text-decoration: underline;
}
.footer-all .hr {
  width: 100%;
  background: #3F3F3F;
  height: 1px;
  margin-bottom: 40px;
}
.footer-all__logo {
  margin-bottom: 40px;
}
.footer-all__copy {
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #7D7D7D;
}
.footer-all__text {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #7D7D7D;
}
.footer-all__text a {
  color: #83B143;
  border-bottom: 1px solid #83B143;
  padding-bottom: 3.5px;
}
.footer-all__text a:hover {
  color: #91db2a;
  border-bottom: 1px solid #91db2a;
}
@media (max-width: 767.98px) {
  .footer {
    padding: 20px 20px 40px 20px;
  }
  .footer-all__nav ul {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .footer-all__nav ul li {
    display: flex;
    justify-content: center;
    margin-right: 0;
    width: 45%;
    text-align: center;
    margin-bottom: 20px;
  }
  .footer-all__nav ul li:nth-child(3), .footer-all__nav ul li:nth-child(4) {
    margin-bottom: 0px;
  }
  .footer-all__logo img {
    max-width: 216px;
  }
  .footer-all__copy {
    text-align: center;
  }
}
@supports (-webkit-appearance:none){
.promo-all .swiper-wrapper .swiper-slide{background-image:url(../img/back-one.webp)}
.services .container .services-all__main .main-item:nth-child(1){background-image:url(../img/card1.webp)}
.services .container .services-all__main .main-item:nth-child(2){background-image:url(../img/card2.webp)}
.services .container .services-all__main .main-item:nth-child(3){background-image:url(../img/card3.webp)}
.services .container .services-all__third{background-image:url(../img/back-two.webp)}
.desc{background-image:url(../img/back-three.webp)}
}

 @media (min-width: 1920px){

@supports (-webkit-appearance:none){

}

}

 @media (max-width: 1320px){

@supports (-webkit-appearance:none){

}

}

 @media (max-width: 1280.98px){

@supports (-webkit-appearance:none){
.promo-all .swiper-wrapper .swiper-slide{background-image:url(../img/back-one.webp)}
}

}

 @media (max-width: 1024.98px){

@supports (-webkit-appearance:none){
.promo-all .swiper-wrapper .swiper-slide{background-image:url(../img/back-one.webp)}
}

}

 @media (min-width: 1025px) and (max-width: 1300px){

@supports (-webkit-appearance:none){

}

}

 @media (max-width: 767.98px){

@supports (-webkit-appearance:none){
.promo-all .swiper-wrapper .swiper-slide{background-image:url(../img/back-one.webp)}
.services .container .services-all__third{background-image:url(../img/back-two-adaptive.webp)}
}

}

 @media (max-width: 660px){

@supports (-webkit-appearance:none){
.promo-all .swiper-wrapper .swiper-slide{background-image:url(../img/back-one.webp)}
}

}

 @media (max-width: 576.98px){

@supports (-webkit-appearance:none){

}

}
