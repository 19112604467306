@import url(https://fonts.googleapis.com/css?family=Lato:100,100italic,300,300italic,regular,italic,700,700italic,900,900italic);
@import "imports/breakpoints.scss";
@import "imports/colors.scss";
html,
body {
    font-family: 'Lato', sans-serif;
    background-color: #f7f9f8;
}

.animate__animated {
    --animate-duration: 0.5s;
}

$bar-width: 22px;
$bar-height: 2px;
$bar-spacing: 8px;
.svg {
    transform: rotate(180deg);
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 400;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 200, 400;
        stroke-dashoffset: -70;
    }
    100% {
        stroke-dasharray: 200, 400;
        stroke-dashoffset: -280;
    }
}

@keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

//Preloader
.page-loaded .page-preloader {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 0.4s ease-in-out 0.2s, visibility 0s ease-in-out 0.6s;
    transition: opacity 0.4s ease-in-out 0.2s, visibility 0s ease-in-out 0.6s;
}

.page-preloader {
    z-index: 9999;
    background: #fff;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    .circular {
        animation: rotate 2s linear infinite;
        height: 100px;
        left: 50%;
        position: absolute;
        top: 50%;
        margin: -50px 0 0 -50px;
        transition: all .2s ease;
        transform: rotate(360deg);
        width: 100px;
        z-index: 4;
        .path {
            stroke-dasharray: 1, 1000;
            stroke-dashoffset: 0;
            animation: dash 1.5s ease-in-out infinite;
            stroke: #49191f;
        }
    }
    .logo {
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -50px 0 0 -50px;
        height: 100px;
        width: 100px;
        z-index: 2;
        .st0 {
            fill-rule: evenodd;
            clip-rule: evenodd;
            fill: none;
        }
        .st1 {
            fill: none;
        }
        .st2 {
            fill: #49191f;
        }
    }
}

//Header
.header {
    background: linear-gradient(180deg, #f7f9f9 0, rgba(247, 249, 249, .58) 71.35%, rgba(247, 249, 249, 0) 100%);
    position: absolute;
    z-index: 2;
    width: 100%;
    padding: 48px 40px 0;
    &-all {
        display: flex;
        flex-direction: column;
        height: auto;
        &__top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 32px;
            .header-profile {
                display: flex;
                align-items: center;
                &__phone {
                    a {
                        display: flex;
                        align-items: center;
                        .phone-number {
                            color: $subtitleColor;
                            font-weight: 400;
                            font-size: 22px;
                            line-height: 26px;
                            letter-spacing: 0.02em;
                            margin-left: 24px;
                            transition: 1s;
                            &:hover {
                                color: #707070;
                                transform: scale(1.1);
                            }
                        }
                    }
                }
                &__account {
                    margin-left: 24px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 3px 0 35px;
                    width: 232px;
                    height: 40px;
                    background: rgba(255, 255, 255, 0.5);
                    border-radius: 28px;
                    position: relative;
                    transition: 0.3s;
                    &:hover {
                        .dropdown-block {
                            opacity: 1;
                            visibility: visible;
                            transition: opacity 0.3s ease-in-out, visibility 0s ease-in-out 0s;
                        }
                    }
                    .account-sign {
                        display: flex;
                        align-items: center;
                        &__login {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 17px;
                            color: $subtitleColor;
                        }
                        &__reg {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 17px;
                            color: $subtitleColor;
                        }
                        span {
                            margin: 0 4px;
                        }
                    }
                    .account-logo {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 34px;
                        height: 34px;
                        border-radius: 50%;
                        background: #F7F9F9;
                    }
                    .dropdown-block {
                        position: absolute;
                        top: 100%;
                        background: $buttonColor;
                        border-radius: 20px;
                        width: 232px;
                        left: 50%;
                        margin: 7px 0 0 -116px;
                        opacity: 0;
                        visibility: hidden;
                        transition: opacity 0.3s ease-in-out, visibility 0s ease-in-out 0.3s;
                        a {
                            display: block;
                            padding: 17px 20px;
                            color: #fff;
                            font-size: 14px;
                            &:hover {
                                color: $textColor;
                            }
                            &:first-child {
                                border-bottom: 1px solid #eae8e9;
                            }
                        }
                        &:before {
                            content: '';
                            width: 0;
                            border: solid transparent;
                            height: 0;
                            bottom: 100%;
                            left: 35px;
                            display: block;
                            position: absolute;
                            border-width: 0 5.5px 6px 5.5px;
                            border-bottom-color: $buttonColor;
                        }
                    }
                }
            }
        }
        &__nav {
            width: 100%;
            background: #FFFFFF;
            box-shadow: 0px 1px 3px rgba(25, 30, 30, 0.08);
            border-radius: 28px;
            .nav-list {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .nav-item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 56px;
                    padding: 0 25px;
                    transition: 1s;
                    cursor: pointer;
                    flex: 1;
                    width: 100%;
                    border-radius: 16px 16px 0 0;
                    a {
                        color: $subtitleColor;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                        text-align: center;
                    }
                    &:first-child {
                        border-radius: 28px 28px 0 28px;
                        &:hover {
                            border-radius: 28px 28px 0 28px;
                        }
                    }
                    &:last-child {
                        cursor: auto;
                        margin-right: 0;
                        padding: 0 0 0 25px;
                        justify-content: flex-end;
                        &:hover {
                            background: transparent;
                        }
                    }
                    form {
                        display: flex;
                        align-items: center;
                        padding-right: 3px;
                        input {
                            border: 0;
                            outline: none;
                            width: 220px;
                            font-weight: 400;
                            font-size: 14px;
                            height: 36px;
                            line-height: 17px;
                            color: #919497;
                            padding-bottom: 6px;
                            border-bottom: 1px solid #D0D0D0;
                        }
                        .search {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-left: 11px;
                            line-height: normal;
                            font-size: 0;
                            width: 50px;
                            height: 50px;
                            border-radius: 50%;
                            background-color: $buttonColor;
                        }
                    }
                    &:hover {
                        background: $buttonColor;
                        border-radius: 16px 16px 0px 0px;
                        a {
                            color: #fff;
                        }
                    }
                }
                .hr-wrapper {
                    .nav-hr {
                        height: 32px;
                        -moz-width: 2px;
                        width: 1px;
                        background: #E5E5E5;
                        border-radius: 1px;
                    }
                }
            }
        }
    }
    @media (min-width: 1920px) {
        width: 100%;
        left: 50%;
        transform: translate(-50%, 0);
        .header-all {
            width: 1920px;
            margin: 0 auto;
        }
    }
    @media (max-width: 1320px) {
        &-all {
            &__nav {
                .nav-list {
                    .nav-item {
                        a {
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: $xl) {
        &-all {
            &__nav {
                .nav-list {
                    flex-wrap: nowrap;
                    .nav-item {
                        width: fit-content;
                        padding: 0 15px;
                        a {
                            font-size: 12px;
                        }
                        &:last-child {
                            padding: 0 0 0 15px;
                            width: auto;
                            form {
                                input {
                                    width: auto;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width: $lg) {
        display: none;
    }
}

//headerAdaptive
.headerAdaptive {
    display: none;
    &-all {
        display: flex;
        flex-direction: column;
        &__top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #fff;
            padding: 12px 16px;
            .logo {
                img {
                    max-width: 187px;
                }
            }
            .right {
                display: flex;
                align-items: center;
                &-phone,
                &-acc {
                    cursor: pointer;
                    background: #F7F9F9;
                    border-radius: 50%;
                    border: 1px solid #FFFFFF;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    box-shadow: 0px 1px 3px rgba(25, 30, 30, 0.08);
                }
                &-acc {
                    margin-left: 16px;
                }
            }
        }
        &__bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #f7f9f9;
            padding: 15px 16px;
            height: 48px;
            .menu-wrapper {
                display: block;
                width: $bar-width;
                height: $bar-height + $bar-spacing*2;
                cursor: pointer;
                .hamburger-menu {
                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        bottom: $bar-spacing;
                        background: $subtitleColor;
                        transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: $bar-spacing;
                        background: $subtitleColor;
                        transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1)
                    }
                    position: relative;
                    transform: translateY($bar-spacing);
                    background: $subtitleColor;
                    transition: all 0ms 300ms;
                    &.animate {
                        background: rgba(255, 255, 255, 0);
                    }
                    &.animate::before {
                        bottom: 0;
                        transform: rotate(-45deg);
                        transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
                    }
                    &.animate::after {
                        top: 0;
                        transform: rotate(45deg);
                        transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
                    }
                }
                .hamburger-menu,
                .hamburger-menu:after,
                .hamburger-menu:before {
                    width: $bar-width;
                    height: $bar-height;
                    border-radius: 2px;
                }
            }
            .menu-two {
                background: $buttonColor;
                width: 48px;
                height: 48px;
                display: flex;
                justify-content: center;
                border-radius: 0px 8px 0px 0px;
                position: relative;
                .hamburger-menu {
                    &.animate {
                        position: absolute;
                        top: 35%;
                        &::after,
                        &::before {
                            background-color: #fff;
                        }
                    }
                }
            }
            .menu-form {
                form {
                    display: flex;
                    align-items: center;
                    input {
                        border: 0;
                        outline: none;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;
                        color: $subtitleColor;
                        text-align: right;
                        width: 193px;
                        background: transparent;
                    }
                    svg {
                        width: 16px;
                        height: 16px;
                        margin-left: 16px;
                        cursor: pointer;
                        path {
                            fill: #C41D39;
                        }
                    }
                }
            }
        }
        .bottom-two {
            padding: 15px 16px 15px 0;
        }
    }
    @media (max-width: $lg) {
        display: block;
    }
}

//Adaptive
.adaptive {
    display: none;
    position: absolute;
    top: 112px;
    z-index: 2;
    width: 100%;
    &-all {
        border-top: 2px solid $buttonColor;
        &__nav {
            .nav-list {
                display: flex;
                flex-direction: column;
                .nav-item {
                    padding: 26px 32px;
                    background: #FFFFFF;
                    box-shadow: inset 0px -1px 0px #EAEDF3;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    a {
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 19px;
                        color: $textColor;
                    }
                    .overlay-arrow {
                        svg {
                            transition: 0.5s;
                            cursor: pointer;
                        }
                    }
                }
                .open-active {
                    background-color: $buttonColor;
                    a {
                        color: #fff;
                    }
                    .overlay-arrow {
                        svg {
                            transition: 0.5s;
                            path {
                                fill: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}

//Overlay-two
.overlay-two {
    display: none;
    padding: 0 40px;
    width: 100%;
    transition: opacity 0.3s ease-in-out, visibility 0s ease-in-out 0s;
    &-all {
        display: flex;
        width: 100%;
        border-top: 4px solid $buttonColor;
        transition: opacity 0.3s ease-in-out, visibility 0s ease-in-out 0s;
        background-color: #fff;
        border-radius: 0 0 28px 28px;
        .overlay-item {
            display: flex;
            flex-direction: column;
            width: 100%;
            &:first-child {
                .nav-list {
                    border-radius: 0 0 0 28px;
                }
            }
            &:last-child {
                .nav-list {
                    border-radius: 0 0 28px 0;
                }
            }
            .nav-list {
                padding: 20px;
                box-shadow: 0px 1px 3px rgba(25, 30, 30, 0.08);
                &:hover {
                    background: linear-gradient(156.08deg, #ECF2FF 0%, rgba(197, 234, 255, 0.5) 82.16%), #FFFFFF;
                }
                a {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: $subtitleColor;
                    margin-bottom: 12px;
                    display: block;
                    &:hover {
                        text-decoration: underline;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    @media (min-width: 1920px) {
        width: 1920px;
        left: 50%;
        transform: translate(-50%, 0);
    }
}

//Overlay
.overlay {
    display: none;
    padding: 0 40px;
    width: 100%;
    transition: opacity 0.3s ease-in-out, visibility 0s ease-in-out 0s;
    &-all {
        display: flex;
        width: 100%;
        border-top: 4px solid $buttonColor;
        transition: opacity 0.3s ease-in-out, visibility 0s ease-in-out 0s;
        .overlay-item {
            display: flex;
            flex-direction: column;
            width: 100%;
            &:first-child {
                max-width: 736px;
            }
            &__title {
                padding: 25px 10px 20px 40px;
                background: #F5F9FF;
                box-shadow: inset 0px -1px 0px #EAEDF3, inset -1px 0px 0px #EAEDF3;
            }
            .nav-wrapper {
                display: flex;
                flex-wrap: wrap;
                width: 736px;
                .overlay-item__nav {
                    display: flex;
                    flex-direction: column;
                    padding: 16px 16px 16px 40px;
                    background: #FFFFFF;
                    width: 368px;
                    box-shadow: inset -1px 0px 0px #EAEDF3, inset 0px -1px 0px #EAEDF3;
                    &:hover {
                        background: linear-gradient(156.08deg, #ECF2FF 0%, rgba(197, 234, 255, 0.5) 82.16%), #FFFFFF;
                        .nav-title {
                            color: $textColor;
                        }
                    }
                    &:nth-child(1) {
                        order: 1;
                        height: 184px;
                    }
                    &:nth-child(2) {
                        order: 3;
                        height: 184px;
                    }
                    &:nth-child(3) {
                        order: 5;
                        height: 64px;
                        border-radius: 0 0 0 28px;
                    }
                    &:nth-child(4) {
                        order: 2;
                        height: 144px;
                    }
                    &:nth-child(5) {
                        order: 4;
                        height: 184px;
                        margin-top: -40px;
                    }
                    &:nth-child(6) {
                        order: 6;
                        height: 104px;
                        margin-top: -40px;
                    }
                    .nav-title {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                        color: $buttonColor;
                        margin-bottom: 16px;
                        transition: 0.5s;
                    }
                    .nav-list {
                        padding-left: 16px;
                        a {
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 24px;
                            color: $subtitleColor;
                            margin-bottom: 12px;
                            display: block;
                            &:hover {
                                text-decoration: underline;
                            }
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
        &__two {
            &:last-child {
                .overlay-item__nav {
                    border-radius: 0 0 28px 0;
                }
            }
            .overlay-item__title {
                padding: 25px 10px 20px 25px;
            }
            .overlay-item__nav {
                display: flex;
                flex-direction: column;
                flex: 1;
                width: 100%;
                padding: 16px 16px 16px 16px;
                background: #FFFFFF;
                box-shadow: inset -1px 0px 0px #EAEDF3, inset 0px -1px 0px #EAEDF3;
                &:hover {
                    background: linear-gradient(156.08deg, #ECF2FF 0%, rgba(197, 234, 255, 0.5) 82.16%), #FFFFFF;
                }
                .nav-list {
                    a {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: $subtitleColor;
                        margin-bottom: 12px;
                        display: block;
                        &:hover {
                            text-decoration: underline;
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
    @media (min-width: 1920px) {
        width: 1920px;
        left: 50%;
        transform: translate(-50%, 0);
    }
    @media (min-width: 1025px) and (max-width: 1300px) {
        &-all {
            .overlay-item {
                .nav-wrapper {
                    width: 450px;
                    .overlay-item__nav {
                        padding: 16px 16px 16px 25px;
                        width: 50%;
                        height: auto!important;
                        margin-top: 0!important;
                        .nav-list {
                            padding-left: 10px;
                        }
                    }
                }
            }
        }
    }
}

//overlay-adaptive-one
.overlay-adaptive-one {
    display: none;
    .nav-list {
        display: flex;
        flex-direction: column;
        .nav-item {
            padding: 13px 32px;
            background: #FFFFFF;
            box-shadow: inset 0px -1px 0px #EAEDF3;
            display: flex;
            justify-content: space-between;
            align-items: center;
            a {
                font-weight: 400;
                font-size: 18px;
                line-height: 19px;
                color: $textColor;
            }
            .overlay-arrow {
                svg {
                    transition: 0.5s;
                    cursor: pointer;
                }
            }
        }
        .open-active-two {
            background-color: #f5f9ff;
            a {
                color: $textColor;
            }
            .overlay-arrow {
                svg {
                    transition: 0.5s;
                    path {
                        fill: #919497;
                    }
                }
            }
        }
        .drop-two {
            display: none;
            .nav-wrapper {
                .overlay-item__nav {
                    display: flex;
                    flex-direction: column;
                    padding: 16px 16px 16px 32px;
                    background: #FFFFFF;
                    width: 100%;
                    box-shadow: inset -1px 0px 0px #EAEDF3, inset 0px -1px 0px #EAEDF3;
                    height: auto;
                    &:hover {
                        background: linear-gradient(156.08deg, #ECF2FF 0%, rgba(197, 234, 255, 0.5) 82.16%), #FFFFFF;
                        .nav-title {
                            color: $textColor;
                        }
                    }
                    .nav-title {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                        color: $buttonColor;
                        margin-bottom: 16px;
                        transition: 0.5s;
                    }
                    .nav-list {
                        padding-left: 16px;
                        a {
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 24px;
                            color: $subtitleColor;
                            margin-bottom: 12px;
                            display: block;
                            &:hover {
                                text-decoration: underline;
                            }
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
        .drop-three {
            display: none;
            .overlay-item__nav {
                display: flex;
                flex-direction: column;
                flex: 1;
                width: 100%;
                padding: 16px 16px 16px 32px;
                background: #FFFFFF;
                box-shadow: inset -1px 0px 0px #EAEDF3, inset 0px -1px 0px #EAEDF3;
                &:hover {
                    background: linear-gradient(156.08deg, #ECF2FF 0%, rgba(197, 234, 255, 0.5) 82.16%), #FFFFFF;
                }
                .nav-list {
                    a {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: $subtitleColor;
                        margin-bottom: 12px;
                        display: block;
                        &:hover {
                            text-decoration: underline;
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        .drop-four {
            display: none;
            .overlay-item__nav {
                display: flex;
                flex-direction: column;
                flex: 1;
                width: 100%;
                padding: 16px 16px 16px 32px;
                background: #FFFFFF;
                box-shadow: inset -1px 0px 0px #EAEDF3, inset 0px -1px 0px #EAEDF3;
                &:hover {
                    background: linear-gradient(156.08deg, #ECF2FF 0%, rgba(197, 234, 255, 0.5) 82.16%), #FFFFFF;
                }
                .nav-list {
                    a {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: $subtitleColor;
                        margin-bottom: 12px;
                        display: block;
                        &:hover {
                            text-decoration: underline;
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        .drop-five {
            display: none;
            .overlay-item__nav {
                display: flex;
                flex-direction: column;
                flex: 1;
                width: 100%;
                padding: 16px 16px 16px 32px;
                background: #FFFFFF;
                box-shadow: inset -1px 0px 0px #EAEDF3, inset 0px -1px 0px #EAEDF3;
                &:hover {
                    background: linear-gradient(156.08deg, #ECF2FF 0%, rgba(197, 234, 255, 0.5) 82.16%), #FFFFFF;
                }
                .nav-list {
                    a {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: $subtitleColor;
                        margin-bottom: 12px;
                        display: block;
                        &:hover {
                            text-decoration: underline;
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

//overlay-adaptive-two
.overlay-adaptive-two {
    display: none;
    &-all {
        display: flex;
        width: 100%;
        transition: opacity 0.3s ease-in-out, visibility 0s ease-in-out 0s;
        background-color: #fff;
        flex-direction: column;
        .overlay-item {
            display: flex;
            flex-direction: column;
            width: 100%;
            .nav-list {
                padding: 20px;
                border-bottom: 1px solid rgb(25 30 30 / 8%);
                &:hover {
                    background: linear-gradient(156.08deg, #ECF2FF 0%, rgba(197, 234, 255, 0.5) 82.16%), #FFFFFF;
                }
                a {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: $subtitleColor;
                    margin-bottom: 12px;
                    display: block;
                    &:hover {
                        text-decoration: underline;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

//Promo
.promo {
    margin-bottom: 30px;
    &-all {
        .swiper {
            width: 100%;
            height: 752px;
            &-wrapper {
                .swiper-slide {
                    background-image: url(../img/back-one.png);
                    background: linear-gradient(180deg, #F7F9F9 0%, rgba(247, 249, 249, 0.58) 71.35%, rgba(247, 249, 249, 0) 100%);
                    background-position: center;
                    background-size: cover;
                    .container {
                        height: 100%;
                        .swiper-slide-desc {
                            display: flex;
                            flex-direction: column;
                            margin-top: 10%;
                            height: 100%;
                            justify-content: center;
                            margin-bottom: 50px;
                            .title {
                                margin-bottom: 42px;
                                color: $subtitleColor;
                                font-weight: 500;
                                font-size: 66px;
                                line-height: 79px;
                            }
                            .subtitle {
                                font-weight: 400;
                                font-size: 18px;
                                line-height: 34px;
                                color: $textColor;
                                height: 72px;
                            }
                            .buttons {
                                display: flex;
                                align-items: center;
                                a {
                                    display: block;
                                    padding: 10px 29px 13px 29px;
                                    font-weight: 500;
                                    font-size: 16px;
                                    line-height: 19px;
                                    color: $subtitleColor;
                                    border: 2px solid #C41D39;
                                    border-radius: 21px;
                                    margin-right: 30px;
                                    &:hover {
                                        color: #fff;
                                        background: #C41D39;
                                    }
                                    &:last-child {
                                        margin-right: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
            .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
                margin: 0 14px 0 0;
                width: 13px;
                height: 13px;
                background: #fff;
                opacity: 1;
                cursor: pointer;
                &:last-child {
                    margin: 0;
                }
                &-active {
                    background: $buttonColor;
                }
            }
        }
    }
    @media (max-width: $xl) {
        &-all {
            .swiper {
                &-wrapper {
                    .swiper-slide {
                        background-image: url(../img/back-one.png);
                        background-size: cover;
                        background-position: center!important;
                        .container {
                            .swiper-slide-desc {
                                padding: 0 50px;
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width: $lg) {
        &-all {
            .swiper {
                &-wrapper {
                    .swiper-slide {
                        background-image: url(../img/back-one.png);
                        background-size: cover;
                        background-position: center!important;
                        .container {
                            background: linear-gradient(180deg, rgba(247, 249, 249, .7) 0, rgba(247, 249, 249, .58) 71.35%, rgba(247, 249, 249, 0) 100%);
                            .swiper-slide-desc {
                                margin-left: 0;
                                padding-left: 15%;
                                margin-top: 0;
                                height: 438px;
                                justify-content: flex-end;
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width: $md) {
        &-all {
            .swiper {
                height: 456px;
                &-wrapper {
                    .swiper-slide {
                        background-image: url(../img/back-one.png);
                        background-size: cover;
                        background-position: center!important;
                        .container {
                            background: linear-gradient(180deg, rgba(247, 249, 249, .7) 0, rgba(247, 249, 249, .58) 71.35%, rgba(247, 249, 249, 0) 100%);
                            .swiper-slide-desc {
                                margin-top: 0;
                                padding: 0 20px;
                                margin-left: 0;
                                height: 287px;
                                justify-content: flex-end;
                                .title {
                                    font-size: 45px;
                                    line-height: 57px;
                                    margin-bottom: 20px;
                                }
                                .subtitle {
                                    height: auto;
                                    margin-bottom: 20px;
                                }
                            }
                        }
                    }
                }
                .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
                .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
                    margin: 0 14px 0 0;
                    width: 13px;
                    height: 13px;
                    background: #fff;
                    opacity: 1;
                    cursor: pointer;
                    &:last-child {
                        margin: 0;
                    }
                    &-active {
                        background: $buttonColor;
                    }
                }
            }
        }
    }
    @media (max-width: 660px) {
        &-all {
            .swiper {
                height: 456px;
                &-wrapper {
                    .swiper-slide {
                        background-image: url(../img/back-one.png);
                        background-size: cover;
                        background-position: -500px!important;
                        .container {
                            background: linear-gradient(180deg, rgba(247, 249, 249, .7) 0, rgba(247, 249, 249, .58) 71.35%, rgba(247, 249, 249, 0) 100%);
                            .swiper-slide-desc {
                                margin-top: 0;
                                padding: 0 20px;
                                margin-left: 0;
                                height: 287px;
                                justify-content: flex-end;
                                .title {
                                    font-size: 45px;
                                    line-height: 57px;
                                    margin-bottom: 20px;
                                }
                                .subtitle {
                                    height: auto;
                                    margin-bottom: 20px;
                                }
                            }
                        }
                    }
                }
                .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
                .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
                    margin: 0 14px 0 0;
                    width: 13px;
                    height: 13px;
                    background: #fff;
                    opacity: 1;
                    cursor: pointer;
                    &:last-child {
                        margin: 0;
                    }
                    &-active {
                        background: $buttonColor;
                    }
                }
            }
        }
    }
    @media (max-width: $sm) {
        &-all {
            .swiper {
                &-wrapper {
                    .swiper-slide {
                        background-size: cover;
                        background-position: center;
                        .container {
                            .swiper-slide-desc {
                                margin-top: 0px;
                                padding: 0;
                                margin-left: 0;
                                height: 100%;
                                justify-content: center;
                                .title {
                                    font-size: 28px;
                                    line-height: 34px;
                                    margin-bottom: 16px;
                                }
                                .subtitle {
                                    height: auto;
                                    margin-bottom: 20px;
                                    font-size: 16px;
                                    line-height: 22px;
                                    margin-bottom: 40px;
                                }
                                .buttons {
                                    flex-direction: column;
                                    a {
                                        margin-right: 0px;
                                        display: flex;
                                        justify-content: center;
                                        width: 100%;
                                        margin-bottom: 10px;
                                        &:last-child {
                                            margin-bottom: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
                .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
                    margin: 0 14px 0 0;
                    width: 13px;
                    height: 13px;
                    background: #fff;
                    opacity: 1;
                    cursor: pointer;
                    &:last-child {
                        margin: 0;
                    }
                    &-active {
                        background: $buttonColor;
                    }
                }
            }
        }
    }
}

//Services
.services {
    margin-bottom: 60px;
    .container {
        .services-all {
            display: flex;
            flex-direction: column;
            &__title {
                font-weight: 500;
                font-size: 38px;
                line-height: 46px;
                color: #000;
                margin-bottom: 42px;
            }
            &__main {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 30px;
                .main-item {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding: 38px 40px 30px 40px;
                    height: 362px;
                    background-size: cover;
                    &__top {
                        display: flex;
                        flex-direction: column;
                        max-width: 290px;
                        .title {
                            font-weight: 400;
                            font-size: 24px;
                            line-height: 27px;
                            color: $textColor;
                            margin-bottom: 17px;
                        }
                        .subtitle {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 17px;
                            color: $subtitleColor;
                        }
                    }
                    &__bottom {
                        .button {
                            transition: 1s;
                            &:hover {
                                transform: scale(1.1);
                            }
                            a {
                                padding: 10px 29px 13px 29px;
                                background-color: $buttonColor;
                                border-radius: 21px;
                                color: #fff;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 19px;
                                display: block;
                                width: fit-content;
                            }
                        }
                    }
                    &:nth-child(1) {
                        background-image: url(../img/card1.png);
                    }
                    &:nth-child(2) {
                        background-image: url(../img/card2.png);
                    }
                    &:nth-child(3) {
                        background-image: url(../img/card3.png);
                    }
                }
            }
            &__third {
                background-image: url(../img/back-two.png);
                display: flex;
                flex-direction: column;
                height: 368px;
                align-items: end;
                justify-content: center;
                .third-wrapper {
                    max-width: 570px;
                    .title {
                        margin-bottom: 40px;
                        font-weight: 400;
                        font-size: 38px;
                        line-height: 44px;
                        color: $subtitleColor;
                    }
                    .text {
                        display: flex;
                        align-items: center;
                        margin-bottom: 48px;
                        span {
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 44px;
                            color: $subtitleColor;
                        }
                        img {
                            margin-left: 16px;
                        }
                    }
                    .buttons {
                        display: flex;
                        align-items: center;
                        a {
                            padding: 10px 29px 13px 29px;
                            background-color: transparent;
                            border: 2px solid #0066B3;
                            border-radius: 21px;
                            color: #0066B3;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 19px;
                            display: block;
                            width: fit-content;
                            transition: 1s;
                            &:hover {
                                transform: scale(1.1);
                            }
                            &:last-child {
                                color: #fff;
                                background-color: #0066B3;
                                margin-left: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width: $xl) {
        .container {
            padding: 0 15px;
            .services-all {
                max-width: 100%;
                align-items: center;
                &__title {
                    width: 100%;
                }
                &__main {
                    flex-wrap: nowrap;
                    justify-content: center;
                    .main-item {
                        flex-direction: column;
                        margin-bottom: 0px;
                        width: 100%;
                        max-width: 370px;
                        margin-right: 10px;
                        padding: 30px;
                        &:nth-child(1) {
                            background-position: center;
                        }
                        &:nth-child(2) {
                            background-position: center;
                        }
                        &:nth-child(3) {
                            background-position: center;
                        }
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
                &__third {
                    width: 100%;
                    max-width: 770px;
                    padding: 0 20px;
                    .third-wrapper {
                        max-width: 400px;
                    }
                }
            }
        }
    }
    @media (max-width: $md) {
        .container {
            padding: 0 15px;
            .services-all {
                &__title {
                    font-size: 24px;
                    line-height: 29px;
                }
                &__main {
                    flex-direction: column;
                    .main-item {
                        flex-direction: column;
                        margin-bottom: 20px;
                        width: 100%!important;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        &:nth-child(1) {
                            margin-right: 0px;
                        }
                        &:nth-child(2) {
                            margin-left: 0px;
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
                &__third {
                    background-image: url(../img/back-two-adaptive.png);
                    height: 368px;
                    max-width: 370px;
                    background-size: cover;
                    background-position: top;
                    padding: 0 20px;
                    .third-wrapper {
                        width: 100%;
                        max-width: 570px;
                        text-align: center;
                        .title {
                            font-size: 25px;
                            line-height: 37px;
                            margin-bottom: 20px;
                        }
                        .text {
                            flex-direction: column;
                            text-align: center;
                            margin-bottom: 25px;
                            span {
                                font-size: 16px;
                                line-height: 28px;
                                margin-bottom: 10px;
                            }
                            img {
                                margin-left: 0;
                            }
                        }
                        .buttons {
                            flex-direction: column;
                            a {
                                &:first-child {
                                    margin-bottom: 10px;
                                }
                                &:last-child {
                                    margin-left: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width: $sm) {
        .container {
            padding: 0 15px;
        }
    }
}

//Info
.info {
    margin-bottom: 60px;
    .container {
        .info-all {
            display: flex;
            flex-direction: column;
            &__title {
                font-weight: 500;
                font-size: 38px;
                line-height: 46px;
                color: #000;
                margin-bottom: 42px;
            }
            &__main {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 30px;
                a {
                    .main-item {
                        padding: 40px 40px 29px 40px;
                        background-color: #fff;
                        box-shadow: 0 1px 3px rgb(25 30 30 / 8%);
                        height: 288px;
                        border: 1px solid #fff;
                        transition: 0.5s;
                        &:hover {
                            box-shadow: 0px 1px 10px rgba(25, 30, 30, 0.1);
                            border: 1px solid #83B143;
                        }
                        &__img {
                            margin-bottom: 24px;
                        }
                        &__title {
                            margin-bottom: 16px;
                            color: $textColor;
                            font-weight: 500;
                            font-size: 24px;
                            line-height: 27px;
                        }
                        &__subtitle {
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 24px;
                            color: $subtitleColor;
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width: $xl) {
        .container {
            padding: 0 40px;
            .info-all {
                &__main {
                    grid-template-columns: 1fr 1fr;
                    a {
                        .main-item {
                            height: 330px;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: $md) {
        .container {
            padding: 0 40px;
            .info-all {
                &__title {
                    font-size: 24px;
                    line-height: 29px;
                }
                &__main {
                    grid-template-columns: 1fr;
                    a {
                        .main-item {
                            height: auto;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: $sm) {
        .container {
            padding: 0 15px;
        }
    }
}

//Desc
.desc {
    background-image: url(../img/back-three.png);
    background-position: center;
    background-size: cover;
    margin-bottom: 60px;
    height: 598px;
    display: flex;
    align-items: center;
    .container {
        .desc-all {
            display: flex;
            align-items: center;
            justify-content: space-between;
            &__text {
                display: flex;
                flex-direction: column;
                max-width: 770px;
                .title {
                    font-weight: 500;
                    font-size: 28px;
                    line-height: 36px;
                    color: $textColor;
                    margin-bottom: 42px;
                    span {
                        color: $buttonColor;
                    }
                }
                .subtitle {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: $textColor;
                    margin-bottom: 56px;
                }
                .button {
                    transition: 1s;
                    width: fit-content;
                    &:hover {
                        transform: scale(1.1);
                    }
                    a {
                        padding: 13px 29px 17px 29px;
                        background-color: $buttonColor;
                        border-radius: 26px;
                        color: #fff;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                        display: block;
                        width: fit-content;
                    }
                }
            }
        }
    }
    @media (max-width: $xl) {
        .container {
            padding: 0 40px;
            .desc-all {
                &__logo {
                    margin-left: 25px;
                }
            }
        }
    }
    @media (max-width: $md) {
        height: auto;
        padding: 20px 0;
        .container {
            padding: 0 40px;
            .desc-all {
                flex-direction: column;
                &__text {
                    order: 2;
                }
                &__logo {
                    order: 1;
                    margin-bottom: 25px;
                    margin-left: 0;
                    img {
                        max-width: 232px;
                    }
                }
            }
        }
    }
    @media (max-width: $sm) {
        .container {
            padding: 0 15px;
        }
    }
}

//News
.news {
    margin-bottom: 152px;
    .container {
        .news-all {
            display: flex;
            flex-direction: column;
            &__title {
                font-weight: 500;
                font-size: 38px;
                line-height: 46px;
                color: #000;
                margin-bottom: 42px;
            }
            &__main {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .main-important {
                    background: linear-gradient(133.59deg, #FFF3E1 0%, #F9FBFF 100%);
                    filter: drop-shadow(0px 1px 3px rgba(25, 30, 30, 0.08));
                    width: 370px;
                    height: 362px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding: 40px 40px 30px 40px;
                    &__top {
                        display: flex;
                        flex-direction: column;
                        max-width: 290px;
                        .title {
                            font-weight: 400;
                            font-size: 24px;
                            line-height: 27px;
                            color: $textColor;
                            margin-bottom: 17px;
                        }
                        .subtitle {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 17px;
                            color: $textColor;
                        }
                    }
                    &__bottom {
                        .button {
                            transition: 1s;
                            width: fit-content;
                            &:hover {
                                transform: scale(1.1);
                            }
                            a {
                                padding: 10px 29px 13px 29px;
                                background-color: $buttonColor;
                                border-radius: 26px;
                                color: #fff;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 19px;
                                display: block;
                                width: fit-content;
                            }
                        }
                    }
                }
                .main-last {
                    display: flex;
                    min-width: 0;
                    max-width: 770px;
                    overflow: hidden;
                    box-shadow: 0px 1px 3px rgba(25, 30, 30, 0.08);
                    .swiper-two {
                        width: 100%;
                        .swiper-slide {
                            max-width: 770px;
                            background-color: #fff;
                            height: 362px;
                            border-radius: 4px;
                            display: flex;
                            flex-direction: column;
                            padding: 40px 40px 30px 40px;
                            .title {
                                font-weight: 400;
                                font-size: 24px;
                                line-height: 27px;
                                margin-bottom: 32px;
                                color: $textColor;
                            }
                            .main {
                                display: flex;
                                justify-content: space-between;
                                .main-item {
                                    max-width: 204px;
                                    display: flex;
                                    flex-direction: column;
                                    &__date {
                                        margin-bottom: 10px;
                                        font-weight: 400;
                                        font-size: 10px;
                                        line-height: 12px;
                                        color: #919497;
                                    }
                                    &__text {
                                        a {
                                            font-weight: 500;
                                            font-size: 16px;
                                            line-height: 21px;
                                            color: $textColor;
                                            margin-bottom: 45px;
                                            display: block;
                                            transition: 0s;
                                            &:hover {
                                                color: $buttonColor;
                                            }
                                        }
                                    }
                                }
                            }
                            .buttons {
                                display: flex;
                                justify-content: space-between;
                                .button {
                                    transition: 1s;
                                    width: fit-content;
                                    &:hover {
                                        transform: scale(1.1);
                                    }
                                    a {
                                        padding: 10px 29px 13px 29px;
                                        background-color: $buttonColor;
                                        border-radius: 26px;
                                        color: #fff;
                                        font-weight: 400;
                                        font-size: 16px;
                                        line-height: 19px;
                                        display: block;
                                        width: fit-content;
                                    }
                                }
                                .button-nav {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    width: 89px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width: $xl) {
        .container {
            padding: 0 40px;
            .news-all {
                &__main {
                    flex-direction: row;
                    align-items: stretch;
                    .main-important {
                        width: 100%;
                        max-width: 700px;
                        margin-bottom: 0px;
                        padding: 30px;
                        margin-right: 10px;
                    }
                    .main-last {
                        max-width: 700px;
                        margin-left: 0;
                        .swiper-two {
                            width: 100%;
                            .swiper-slide {
                                padding: 30px;
                                height: auto;
                                .main {
                                    flex-direction: row;
                                    align-items: start;
                                    justify-content: space-between;
                                    flex: 1;
                                    .main-item {
                                        margin-right: 15px;
                                        &:last-child {
                                            margin-right: 0;
                                        }
                                    }
                                }
                                .buttons {
                                    .button {
                                        text-align: center;
                                        margin-right: 10px;
                                    }
                                    .button-nav {
                                        .swiper-next {
                                            margin-left: 5px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width: $md) {
        margin-bottom: 46px;
        .container {
            padding: 0 40px;
            .news-all {
                &__title {
                    font-size: 24px;
                    line-height: 29px;
                }
                &__main {
                    flex-direction: column;
                    .main-important {
                        width: 100%;
                        max-width: 770px;
                        margin-bottom: 20px;
                    }
                    .main-last {
                        max-width: 100%;
                        .swiper-two {
                            width: 100%;
                            .slide-one {
                                .main {
                                    .main-item {
                                        &:nth-child(2),
                                        &:nth-child(3) {
                                            display: none;
                                        }
                                    }
                                }
                            }
                            .slide-two {
                                .main {
                                    .main-item {
                                        &:nth-child(1),
                                        &:nth-child(3) {
                                            display: none;
                                        }
                                    }
                                }
                            }
                            .slide-three {
                                .main {
                                    .main-item {
                                        &:nth-child(2),
                                        &:nth-child(1) {
                                            display: none;
                                        }
                                    }
                                }
                            }
                            .swiper-slide {
                                padding: 30px;
                                height: auto;
                                justify-content: space-between;
                                .main {
                                    flex-direction: column;
                                    align-items: start;
                                    .main-item {
                                        max-width: 100%;
                                    }
                                }
                                .buttons {
                                    .button {
                                        text-align: center;
                                        margin-right: 10px;
                                    }
                                    .button-nav {
                                        .swiper-next {
                                            margin-left: 5px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width: $sm) {
        .container {
            padding: 0 15px;
        }
    }
}

//Footer
.footer {
    background-color: $textColor;
    padding-top: 20px;
    padding-bottom: 40px;
    &-all {
        display: flex;
        flex-direction: column;
        align-items: center;
        &__nav {
            margin-bottom: 31px;
            ul {
                display: flex;
                align-items: center;
                li {
                    margin-right: 30px;
                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                        color: #7D7D7D;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
        .hr {
            width: 100%;
            background: #3F3F3F;
            height: 1px;
            margin-bottom: 40px;
        }
        &__logo {
            margin-bottom: 40px;
        }
        &__copy {
            margin-bottom: 8px;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #7D7D7D;
        }
        &__text {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #7D7D7D;
            a {
                color: $buttonColor;
                border-bottom: 1px solid $buttonColor;
                padding-bottom: 3.5px;
                &:hover {
                    color: #91db2a;
                    border-bottom: 1px solid #91db2a;
                }
            }
        }
    }
    @media (max-width: $md) {
        padding: 20px 20px 40px 20px;
        &-all {
            &__nav {
                ul {
                    flex-wrap: wrap;
                    justify-content: space-between;
                    li {
                        display: flex;
                        justify-content: center;
                        margin-right: 0;
                        width: 45%;
                        text-align: center;
                        margin-bottom: 20px;
                        &:nth-child(3),
                        &:nth-child(4) {
                            margin-bottom: 0px;
                        }
                    }
                }
            }
            &__logo {
                img {
                    max-width: 216px;
                }
            }
            &__copy {
                text-align: center;
            }
        }
    }
}